import React from 'react';
import MainLayout from '../../layouts/main';
import { Typography } from '@material-ui/core';
import { Check, Dash } from '../../components/Check';
import PageHead from '../../components/PageHead';
import { SEO } from '../../components/SEO';
import { FeatureTable } from '../../components/FeatureTable';
import { DemoButton } from '../../components/DemoButton';

export default () => (
  <MainLayout>
    <div>
      <SEO
        pathname="/alternatives/trackonomics/"
        title="Looking for Trackonomics alternatives? Try Affilimate"
        description="Looking for the perfect affiliate analytics platform? Here's how Affilimate stacks up against Trackonomics as an alternative."
      />
      <PageHead style={{ marginBottom: 0 }}>
        <Typography
          variant="h3"
          gutterBottom
          component="h1"
          style={{
            maxWidth: '700px',
            margin: '0 auto 36px',
            fontWeight: 900
          }}
        >
          Looking for a Trackonomics alternative?
        </Typography>
        <Typography
          paragraph
          style={{ maxWidth: '600px', padding: '24px', margin: '0 auto' }}
        >
          Trackonomics was an early mover in the affiliate analytics space and
          emphasizes customizable dashboards and high-level reporting.
          <br />
          <br />
          Affilimate is a modern solution that goes beyond reporting and offers
          an advanced suite of in-page optimizations tools, intuitive enough for
          editors with minimal training to maximize content performance.
        </Typography>

        <DemoButton text="Book your demo now" variant="contained" />
      </PageHead>
      <br />
      <br />
      <img
        src="/images/features/new-heatmaps.png"
        style={{
          width: '80%',
          maxWidth: '700px',
          borderRadius: '8px',
          boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
          transform: 'rotate(-2deg)',
          margin: '48px auto',
          display: 'block'
        }}
        alt="Affiliate heatmaps"
      />
      <br />
      <br />
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        style={{ textAlign: 'center', fontWeight: 900 }}
      >
        Affilimate vs. Trackonomics
      </Typography>
      <Typography
        paragraph
        variant="body2"
        color="textSecondary"
        style={{
          maxWidth: '600px',
          padding: '24px',
          textAlign: 'center',
          margin: '0 auto'
        }}
      >
        See if Affilimate or Trackonomics is the better fit for your business.
      </Typography>
      <div style={{ maxWidth: '800px', margin: '96px auto' }}>
        <FeatureTable>
          <thead>
            <tr>
              <th style={{ width: '55%' }}>Feature</th>
              <th style={{ width: '22%' }}>Affilimate</th>
              <th style={{ width: '22%' }}>Trackonomics</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Affiliate Dashboard</td>
              <td>
                <Check />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Revenue Attribution</td>
              <td>
                <Check />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Link Management</td>
              <td>
                <Check />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Page-level Reporting</td>
              <td>
                <Check />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Detailed in-article analytics</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Conversion Heatmaps (incl. element-level revenue)</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Revision tracking</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Pageview data</td>
              <td>
                <Check />
              </td>
              <td>Via 3rd party providers</td>
            </tr>
            <tr>
              <td>Content segmentation</td>
              <td>Ad hoc or automated via tags</td>
              <td>Only automated</td>
            </tr>
            <tr>
              <td>Author reporting</td>
              <td>
                <Check />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Link Generator Tool</td>
              <td>
                <Check />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Link Scanner</td>
              <td>Yes, for Amazon</td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Link Cloaker</td>
              <td>
                <Dash />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Transaction and Link Generation APIs</td>
              <td>
                <Check />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Suitable for coupon-only or cashback sites</td>
              <td>
                <Dash />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Independently owned</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Self-service setup</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Typical onboarding time</td>
              <td>2-4 weeks</td>
              <td>8 weeks</td>
            </tr>
          </tbody>
        </FeatureTable>
        <Typography
          paragraph
          color="textSecondary"
          style={{
            margin: '48px auto 0',
            maxWidth: '800px',
            textAlign: 'center'
          }}
        >
          <strong>Our conclusion.</strong>
          <br />
          Trackonomics was an early mover in affiliate analytics and has a lot
          of reporting features that have been added over the years. This
          experience has led them to being able to handle a lot of potential
          edge cases and unique requests.
          <br />
          <br />
          But this flexibility, comes tradeoffs. Trackonomics is often described
          as complicated, clunky, and difficult to navigate.
          <br />
          <br />
          In terms of surfacing insights that business and editorial teams can
          use to improve performance, Affilimate is a better choice for most
          teams.
          <br />
          <br />
          The detailed in-page reporting and revision tracking makes it simple
          to increase revenue without needing to wade through building new
          widgets everytime you want to dive deep into an article.
          <br />
          <br />
          Plus, our self-service approach means you can set up your account and
          start getting meaningful insights in as little as 24 hours.
          <br />
          <br />
          <strong>Thinking about switching? You're in good company.</strong>
          <br />
          We've helped publishers go from kick-off to go-live in under two
          weeks. Publishers with more custom setups are often ready in under 4
          weeks. We're here to help every step of the way.
        </Typography>
      </div>
      <div style={{ textAlign: 'center', marginBottom: '48px' }}>
        <DemoButton text="Have a call with us" variant="contained" />
      </div>
    </div>
  </MainLayout>
);
